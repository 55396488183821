(function () {
  'use strict';

  class Media {
    constructor(MediaRest, $cookies, $rootScope) {
      this.MediaRest = MediaRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    mediaIndex(media = {}, callback = angular.noop) {
      return this.MediaRest.meidas().get(media,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    mediaCreate(media = {}, callback = angular.noop) {
      return this.MediaRest.meidas().save(Media.setMedia(media),
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    mediaDelete(media = {}, callback = angular.noop) {
      return this.MediaRest.media().remove(media,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    static setMedia(file) {
      var fd = new FormData();
      fd.append('file', file, file.name || 'img.jpeg');
      return fd;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Media
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Media', Media);
}());
